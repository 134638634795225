import React, { useCallback } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useHistory } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import * as r from "../../js/constants/routes";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { historyPush } from "../../js/utils/genericMethods";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { updateEntityTree } from "../../js/redux/actions/userPref";

const OTEntityGrid = ({
    label = t("grid.title.Entities"),
    baseURL = r.oTEntity,
    ...props
}) => {
    const apiRef = useGridApiRef();
    const isCompanyManager = useSelector(state => state.auth.CompanyManager);
    const isHoldingManager = useSelector(state => state.auth.HoldingManager);
    const isEntityManager = useSelector(state => state.auth.EntityManager);
    const addSubEntity = isEntityManager && !isCompanyManager && !isHoldingManager
    const { EntityManagerEntityIDs } = useSelector(state => state.auth);
    const addRights = useEditionRightsPerUserStatus(["EntityManager"]);
    const editingRights = entityID =>
        isCompanyManager || isHoldingManager || EntityManagerEntityIDs?.includes(entityID);
    const history = useHistory();
    const dispatch = useDispatch();

    const onDelete = useCallback(
        () => dispatch(updateEntityTree()),
        [dispatch]
    );

    const gridSpecificColumns = [
        {
            field: "Code",
            headerName: t("field.Code"),
            flex: 1,
            editable: false,
        },
        {
            field: "Name",
            headerName: t("field.Name"),
            flex: 1,
            editable: false,
        },
    ];

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <>
            {editingRights(params.row.ID) && (
                <>
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip
                                title={t("common.EditTreeVisibility")}
                            >
                                <AccountTreeOutlinedIcon />
                            </BasicTooltip>
                        }
                        label={t("common.EditTreeVisibility")}
                        className="textPrimary"
                        onClick={() =>
                            historyPush(
                                history,
                                `/entity-tree-visibility-editor/${params.row.ID}`
                            )
                        }
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.EditEntityExtent")}>
                                <MyLocationOutlinedIcon />
                            </BasicTooltip>
                        }
                        label={t("common.EditEntityExtent")}
                        className="textPrimary"
                        onClick={() =>
                            historyPush(
                                history,
                                `/entity-map-editor/${params.row.ID}`
                            )
                        }
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Edit")}>
                                <EditOutlinedIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Edit")}
                        className="textPrimary"
                        onClick={() =>
                            historyPush(
                                history,
                                `/entity/edit/${params.row.ID}`
                            )
                        }
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                </>
            )}
        </>,
    ];

    return (
        <DataGridComponent
            apiRef={apiRef}
            baseURL={baseURL}
            label={label}
            gridSpecificColumns={gridSpecificColumns}
            toolbar={{
                newAction: (
                    <Button
                        color="secondary"
                        variant="text"
                        size="small"
                        startIcon={<GridAddIcon />}
                        onClick={() =>
                            historyPush(history, "/entity/create/new")
                        }
                    >
                        {addSubEntity ? t("common.AddSubEntity") : t("common.Add")}
                    </Button>
                ),
                add: false,
            }}
            specificActionsColumns={specificActionsColumns}
            isEditDisabled={true}
            isDeleteDisabled={!isCompanyManager && !isHoldingManager}
            onDelete={onDelete}
            editingRights={addRights}
            initialState={{
                sorting: {
                    sortModel: [{ field: "Name", sort: "desc" }],
                },
            }}
            {...props}
        />
    );
};

export default OTEntityGrid;
