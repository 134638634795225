import React, { useState, useEffect } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { BasicButton } from "../../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import {
    addNewModel,
    editModel,
} from "../../../js/utils/backend";
import { FormProvider, useForm } from "react-hook-form";
import {
    VCheckBox,
    VDateOnlyPicker,
    VTextField,
} from "../../Widgets/Custom Inputs/CustomInputs";
import {
    VMultiSelectURL,
    VSelect,
    VSelectURL,
} from "../../Widgets/Custom Inputs/Select";
import { optionsDocumentCategory } from "../../../js/constants/selectOptions";
import { DialogActionsForm } from "../../Widgets/CustomSurfaces";
import * as r from "../../../js/constants/routes";
import { useCheckAndRevalidateToken } from "../../../js/utils/customHooks";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import mime from "mime";
import { SessionVerificationDialog } from "../../Layouts/CommonLayout";
import { useParams } from "react-router-dom";
import { isPhotoVideo } from "./MediaChunksUploaderDialog";
import { updateSpecificNodeEntityTree } from "../../../js/redux/actions/userPref";
import { useDispatch } from "react-redux";
import Axios from "axios";

// Previously, from : src/components/Views/DocumentsView.js

const EditMediaDialog = ({
    openEditDialog,
    setOpenEditDialog,
    treeNode,
    media,
    setMedia,
    token,
    setForceGridRefresh,
    setMediaMetadata,
    mediaMetadata = null,
    setCurrentDocument,
    otEntityId,
    isEddFile = false,
    triggerBatchMetadataDialog,
}) => {
    const { fileType } = useParams();
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const dispatch = useDispatch();
    const { handleSubmit, reset, setValue, watch, formState } = methods;
    const excelExtensions = [
        mime.getExtension("application/vnd.ms-excel"),
        mime.getExtension("application/vnd.oasis.opendocument.spreadsheet"),
        mime.getExtension(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ),
    ];

    const hasPhotoContext = fileType === isPhotoVideo;

    const [isCancelling, setIsCancelling] = useState(false);

const handleCancel = async () => {
    if (isCancelling) return;
    setIsCancelling(true);

    const config = {
        headers: { Authorization: "Bearer " + token },
    };
    try {
        await Axios.delete("Document/lastdocument", config);
        closeAndClearForm();
    } catch (error) {
        console.error("Error deleting document:", error);
        // Optionally handle the error
    } finally {
        setIsCancelling(false);
    }
  };

    /** If a media is uploaded, we'll prefill fields for POST request */
    useEffect(() => {
        if (media?.length > 0 && !triggerBatchMetadataDialog) {
            reset({
                Value: "",
                FileName: media?.name,
                Author: "",
                DocumentCategory: null,
                ParentID: treeNode?.ID,
                OTEntityID: treeNode?.OTEntityID,
                ParentNodeType: treeNode?.NodeType,
            });
        } else if (media.length === 0) {
            setOpenEditDialog(false);
        }
        // eslint-disable-next-line
    }, [media, setValue, treeNode, reset]);

    /** If a document is edited (changing metadata), we'll prefill/reset fields for PUT request */
    useEffect(() => {
        let isSubscribed = true;
        if (mediaMetadata && isSubscribed) {
            let {
                CreatedOn,
                ModificationDate,
                Translations,
                OTEntitySelector,
                OTUserSelector,
                ...rest
            } = mediaMetadata;
            reset({
                RelatedZoneIDs: mediaMetadata.RelatedZones_Select.map(
                    (i, _ix) => i.ObjectID
                ),
                RelatedStudyIDs: mediaMetadata.RelatedStudies_select.map(
                    (i, _ix) => i.ObjectID
                ),
                RelatedStationIDs: mediaMetadata.RelatedStations_select?.map(
                    (i, _ix) => i.ObjectID
                ),
                RelatedStationGroupIDs:
                    mediaMetadata.RelatedStationGroups_select?.map(
                        (i, _ix) => i.ObjectID
                    ),
                OTEntityID: mediaMetadata.OTEntitySelector.ObjectID,
                ...rest,
            });
        }
    }, [mediaMetadata, reset]);

    const manageSubmit = async data => {
        if (mediaMetadata) {
            await editModel(data, mediaMetadata.ID, token, r.document);
            dispatch(updateSpecificNodeEntityTree(data.OTEntityID));
                
            //dispatch(updateEntityTree());
            //.then(response => setCurrentDocument(response.data.ObjectID)); // not sure if it's necessary // single file uploads correctly
        } else if (media) {
            await addNewModel(data, token, r.document);
        }
        closeAndClearForm();
    };

    function closeAndClearForm() {
        setMedia([]);
        //
        reset({
            Value: "",
            FileName: "",
            Author: "",
            DocumentCategory: null,
            PublicationDate: null,
        });
        setOpenEditDialog(false);
        setForceGridRefresh(fgr => !fgr); // will trigger DataGridComponent and force table to refresh
        setMediaMetadata(null);
        setCurrentDocument(null);
    }

    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const [sessionVerification, setSessionVerification] = useState(false);
    useCheckAndRevalidateToken(setSessionVerification, [
        formState.isSubmitting,
    ]);
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    const isDisabled = formState.isSubmitting || !formState.isDirty;
    const hasInitialStudy = Boolean(watch("InitialStudy_select.Value")); // ADD INITIAL STUDY INPUT MEMO TODO

    return (
        <Dialog open={Boolean(openEditDialog)} sx={{ w: 1 }} fullWidth>
            {sessionVerification ? (
                <SessionVerificationDialog
                    sessionVerification={sessionVerification}
                />
            ) : null}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(manageSubmit)}>
                    <DialogTitle>
                        <Box sx={{ typography: "body1", display: "flex" }}>
                            {t("view.document.AddMediaMetadata")}{" "}
                            <Box
                                sx={{
                                    typography: "bodyMajor",
                                    ml: 1 / 2,
                                }}
                            >
                                {watch("Filename")}
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}></DialogContentText>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <VTextField
                                    fieldName={"Value"}
                                    label="Name"
                                    validation={{
                                        required: t(
                                            "input.validation.required"
                                        ),
                                    }}
                                />
                            </Grid>
                            {hasInitialStudy && (
                                <Grid item xs={12}>
                                    <VTextField
                                        fieldName={"InitialStudy_select.Value"}
                                        label={t("field.InitialStudy")}
                                        validation={{
                                            required: t(
                                                "input.validation.required"
                                            ),
                                        }}
                                        disabled={true}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <VTextField
                                    fieldName={"Filename"}
                                    label={t("field.Filename")}
                                    validation={{
                                        required: t(
                                            "input.validation.required"
                                        ),
                                    }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VTextField
                                    label={t("field.Author")}
                                    fieldName={"Author"}
                                    validation={{
                                        required: t(
                                            "input.validation.required"
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VDateOnlyPicker
                                    label={t("field.PublicationDate")}
                                    fieldName={"PublicationDate"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VSelect
                                    options={optionsDocumentCategory.map(e => ({
                                        ...e,
                                        label: t(`${e.label}`)
                                    }))}
                                    label={t("field.DocumentCategory")}
                                    fieldName={"DocumentCategory"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: t(
                                                "input.validation.required"
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                            {hasPhotoContext && (
                                <Grid item xs={12}>
                                    <VSelectURL
                                        label={t("field.PhotoContext")}
                                        URL={`Ref_PhotoContext`}
                                        fieldName={"Ref_PhotoContextID"}
                                        defaultValue={watch(
                                            "Ref_PhotoContext_select"
                                        )}
                                        validation={{
                                            required: {
                                                value: false,
                                                message: t(
                                                    "input.validation.required"
                                                ),
                                            },
                                        }}
                                    />
                                </Grid>
                            )}
                            {!isEddFile ? (
                                <>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"Zone"}
                                            label={t("field.RelatedZones")}
                                            fieldName={"RelatedZoneIDs"}
                                            paramURL={`OTEntityIDWithStudy=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedZones_Select"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"Study"}
                                            label={t("field.RelatedStudies")}
                                            fieldName={"RelatedStudyIDs"}
                                            paramURL={`OTEntityID=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedStudies_select"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"Station"}
                                            label={t("field.RelatedStations")}
                                            fieldName={"RelatedStationIDs"}
                                            paramURL={`OTEntityID=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedStations_select"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"StationGroup"}
                                            label={t(
                                                "field.RelatedStationGroups"
                                            )}
                                            fieldName={"RelatedStationGroupIDs"}
                                            paramURL={`OTEntityID=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedStationGroups_select"
                                            )}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                excelExtensions.filter(e =>
                                    watch("Filename")?.includes(e)
                                ).length > 0 && (
                                    <Grid item xs={6}>
                                        <VCheckBox
                                            label={t("field.IsPrimaryEDDFile")}
                                            fieldName={"IsPrimaryEDDFile"}
                                        />
                                    </Grid>
                                )
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{ py: 2 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={
                                        <ArrowForwardIosIcon
                                            sx={{
                                                fontSize: "0.9rem",
                                                m: 1,
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        flexDirection: "row-reverse",
                                        ml: 1,
                                        mr: 1,
                                        padding: 0,
                                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                                            {
                                                transform: "rotate(90deg)",
                                            },
                                    }}
                                >
                                    <Typography variant="body1">
                                        {t("view.document.MoreInfo")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {!isEddFile && (
                                        <>
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="caption">
                                                    {t(
                                                        "view.document.OriginalStudy"
                                                    )}
                                                </Typography>
                                                <Typography
                                                    sx={{ fontWeight: 500 }}
                                                >
                                                    {mediaMetadata
                                                        ?.InitialStudy_select
                                                        ?.StudyCode &&
                                                        `${mediaMetadata?.InitialStudy_select?.StudyCode}:${mediaMetadata?.InitialStudy_select?.Value}`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mb: 1 }}>
                                                <Typography variant="caption">
                                                    {t(
                                                        "view.document.CurrentStudy"
                                                    )}
                                                </Typography>
                                                <Typography
                                                    sx={{ fontWeight: 500 }}
                                                >
                                                    {mediaMetadata
                                                        ?.CurrentStudy_select
                                                        ?.StudyCode &&
                                                        `${
                                                            mediaMetadata
                                                                ?.CurrentStudy_select
                                                                ?.StudyCode
                                                        }:${
                                                            mediaMetadata
                                                                ?.CurrentStudy_select
                                                                ?.Value
                                                        } ${
                                                            mediaMetadata
                                                                ?.WorkOrder_select
                                                                ?.Value ?? ""
                                                        }`}
                                                    {/* MEMO : The Workorder if available : when an external user will upload a document, it will also contain a workorder */}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                    <Box sx={{ mb: 1 }}>
                                        <Typography variant="caption">
                                            {t("view.document.UploadedBy")}
                                        </Typography>
                                        <Typography sx={{ fontWeight: 500 }}>
                                            {`${mediaMetadata?.UploadedUser}`}
                                        </Typography>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </DialogContent>
                    <DialogActionsForm>
                        <BasicButton
                            type="button"
                            style={{
                                position: "relative",
                                margin: 4,
                            }}
                            onClick={handleCancel}
                            disabled={isCancelling}
                        >
                            {t("common.Cancel")}
                        </BasicButton>
                        <BasicButton
                            type="submit"
                            style={{ margin: 4, marginRight: 0 }}
                            disabled={isDisabled}
                        >
                            {t("common.Save")}
                        </BasicButton>
                    </DialogActionsForm>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default EditMediaDialog;