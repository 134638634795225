import React, { useEffect, useState } from "react";
import { Box, Grid, Link, Paper } from "@mui/material";
import { t } from "react-i18nify";
import { fetchData, fetchDataGrid } from "../../js/utils/backend";
import { useDispatch, useSelector } from "react-redux";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { SubTitle } from "../Widgets/StyledComponents";
import CarouselPictures from "../Widgets/Carousel";
import { LiLink } from "../Widgets/CustomNavigation";
import { ButtonEditElement } from "../Widgets/Custom Inputs/Buttons";
import { PageSection } from "../Widgets/CustomSurfaces";
import { useParams } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { currentEntity } from "../../js/redux/actions/userPref";
import * as cst from "../../js/constants/routes";
import { isArrayLength } from "../../js/utils/genericMethods";
import { uuidv4 } from "../../js/redux/actions/syncTab";
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks"

/** Page view where the user can see main data about accessible entities */
const EntityPageView = ({ route = "OTEntity", ID }) => {
    const token = useSelector(state => state.auth.Token);
    const [entity, setEntity] = useState();
    const [documentList, setDocumentList] = useState();
    const { UrlID } = useParams();
    ID = ID ?? UrlID;
    const dispatch = useDispatch();

    const editingRights = useEditionRightsPerEntityAccess(entity?.ID, ["EntityManager"])
    useEffect(() => {
        const fetching = async () => {
            const entityFetched = await fetchData(route, token, "ID", ID);
            setEntity(entityFetched.data);
            dispatch(
                currentEntity({
                    ObjectID: entityFetched.data.ID,
                    Code: entityFetched.data.Code,
                    Name: entityFetched.data.Name,
                })
            );
        };
        fetching();
    }, [route, token, UrlID, ID, dispatch]);

    useEffect(() => {
        let query = {
            pageSize: 20,
            pageNumber: 1,
            search: "",
            orderBy: "CreatedOn",
            orderDirection: "asc",
        };
        const fetching = async () => {
            let optionalFetchOptions = `Filter[OTEntityID]=${ID}`;
            const documentsFetched = await fetchDataGrid(
                token,
                query,
                cst.document,
                optionalFetchOptions
            );
            setDocumentList(
                documentsFetched?.data?.sort(
                    (a, b) => new Date(b?.CreatedOn) - new Date(a?.CreatedOn)
                )
            );
        };
        fetching();
    }, [token, entity, ID]);

    const contacts = entity?.EntityContacts;

    const ContactList = ({ contactType, title }) => (
        <Box mt={1}>
            <Box sx={{ typography: "bodyMajor" }}>{title}</Box>
            <Grid container>
                {contactType.map(contact => (
                    <Grid item xs={12} lg={6} xl={4} p={1} key={uuidv4()}>
                        <Paper sx={{ p: 2, width: "auto" }} elevation={3}>
                            <Grid>
                                <Grid
                                    item
                                    sx={{
                                        typography: "body1",
                                    }}
                                >
                                    {`${contact.Firstname} ${contact.Lastname}`}
                                </Grid>

                                {contact.EMail && (
                                    <Grid
                                        item
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            typography: "body2",
                                        }}
                                    >
                                        <EmailOutlinedIcon
                                            fontSize="small"
                                            sx={{ mr: 1 }}
                                        />
                                        <Link
                                            href={`mailto:${contact.EMail}`}
                                            underline="hover"
                                        >
                                            {contact.EMail}
                                        </Link>
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        typography: "body2",
                                    }}
                                >
                                    <BadgeOutlinedIcon
                                        fontSize="small"
                                        sx={{ mr: 1 }}
                                    />
                                    <Link
                                        href={
                                            process.env
                                                .REACT_APP_TOTAL_USER_URL +
                                            `/${contact.IGG}`
                                        }
                                        underline="hover"
                                        target="_blank"
                                    >
                                        {contact.IGG}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    return !entity ? (
        <ScreenLoading />
    ) : (
        <Grid>
            <Grid
                container
                sx={{
                    height: 1,
                    borderTop: theme =>
                        `1px solid ${theme.palette.neutral.main}`,
                    p: 1,
                }}
            >
                <Grid
                    item
                    container
                    xs={4}
                    sx={{
                        height: 1,
                    }}
                >
                    <PageSection sx={{ width: 1 }}>
                        {entity?.Images && (
                            <CarouselPictures images={entity.Images} />
                        )}
                    </PageSection>
                    <PageSection sx={{ width: 1, display: "grid" }}>
                        <SubTitle label={t("view.entityPage.Documents")} />
                        <Box
                            component="ul"
                            sx={{
                                typography: "body2",
                                overflowY: "auto",
                                display: "block",
                            }}
                        >
                            {documentList?.map((doc, ixDoc) => {
                                return (
                                    // MEMO : this should be a link in a near future (redirection ? download document ?)
                                    <LiLink key={doc.ID} isComplete={doc.Value}>
                                        {doc.Value ? (
                                            <>
                                                {doc.Value} ({doc.Filename})
                                            </>
                                        ) : (
                                            doc.Filename
                                        )}
                                    </LiLink>
                                );
                            })}
                        </Box>
                    </PageSection>
                </Grid>
                <Grid
                    item
                    container
                    xs={8}
                    sx={{
                        height: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <PageSection>
                        <SubTitle label={t("view.entityPage.Contact")} />
                        {contacts && (
                            <Box>
                                {isArrayLength(contacts.BranchManagers) && (
                                    <ContactList
                                        contactType={contacts.BranchManagers}
                                        title={"Branch Managers"}
                                    />
                                )}
                                {isArrayLength(contacts.EntityManagers) && (
                                    <ContactList
                                        contactType={contacts.EntityManagers}
                                        title={"Entity Managers"}
                                    />
                                )}
                                {isArrayLength(contacts.GisManager) && (
                                    <ContactList
                                        contactType={contacts.GisManager}
                                        title={"Gis Managers"}
                                    />
                                )}
                            </Box>
                        )}
                    </PageSection>
                    <PageSection>
                        <SubTitle label={t("view.entityPage.Location")} />
                        <Box
                            sx={{ typography: "body2" }}
                            dangerouslySetInnerHTML={{
                                __html: entity.EntityLocation,
                            }}
                        />
                    </PageSection>
                    <PageSection>
                        <SubTitle label={t("view.entityPage.History")} />
                        <Box
                            sx={{
                                typography: "body2",
                                listStyle: "circle",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: entity.EntityHistory,
                            }}
                        />
                    </PageSection>
                    <PageSection>
                        <SubTitle label={t("view.entityPage.Operations")} />
                        <Box
                            sx={{ typography: "body2" }}
                            dangerouslySetInnerHTML={{
                                __html: entity.EntityOperations,
                            }}
                        />
                    </PageSection>
                    <Grid flexGrow={1} />
                    <Grid
                        item
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        {entity.CanEdit && editingRights && <ButtonEditElement ID={ID} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EntityPageView;
