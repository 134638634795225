import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IconButton,
    Typography,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Menu,
    Divider,
    Box,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { addNewModel, deleteModel, editModel } from "../../js/utils/backend";
import { FormProvider, useForm } from "react-hook-form";
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import { TranslationDialog } from "../DataGrid/cst_DataGrid";
import { DialogActionsForm } from "../Widgets/CustomSurfaces";

import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import {
    menuBookmarked,
    menuUnbookmarked,
} from "../../js/redux/actions/userPref";
import { useCheckAndRevalidateToken } from "../../js/utils/customHooks";
import { SessionVerificationDialog } from "../Layouts/CommonLayout";
export const ActionMenu = ({ action, Icon, label, disabled }) => (
    <MenuItem onClick={action} sx={{ p: 0, m: 0 }} disabled={disabled}>
        <IconButton
            disabled={disabled}
            sx={{ p: 0.5 }}
            disableRipple
            disableFocusRipple
        >
            {Icon}
        </IconButton>
        <Typography variant="caption" sx={{ mr: 1 }}>
            {label}
        </Typography>
    </MenuItem>
);

export const ContextMenu = ({
    contextMenuState,
    handleClose,
    fref,
    URL,
    Form,
    nodeTypeField = "NodeType",
    nodeIDFIeld = "ID",
    baseURL = URL,
    BaseForm = Form,
    onFormClose = (_node, _isEditing) => {
        /* MARTIN COMMENT : explain what the function will do */
    },
    onDelete = _node => {
        /* MARTIN COMMENT : explain what the function will do */
    },
    CustomActions = _contextMenuState => null,
    isAddDisable = _node => false,
}) => {
    const [tanslationOpen, setTanslationOpen] = useState(false);
    const [editNodeOpen, setEditNodeOpen] = useState(false);
    const [deleteNodeOpen, setDeleteNodeOpen] = useState(false);

    const nodeTitle = contextMenuState?.node?.Value;

    const addAction = useCallback(() => {
        const { node } = contextMenuState;
        setEditNodeOpen({
            parentNode: { ...node },
            ParentID: node.ID === node.OTEntityID ? null : node.ID,
            AllowedNodeTypes: node?.AllowedNodeTypes,
        });
        handleClose();
    }, [handleClose, contextMenuState]);
    const addRootAction = useCallback(() => {
        setEditNodeOpen({});
        handleClose();
    }, [handleClose]);
    const deleteAction = useCallback(() => {
        const { node } = contextMenuState;
        setDeleteNodeOpen({ ...node, ID: node[nodeIDFIeld] });
        handleClose();
    }, [handleClose, contextMenuState, nodeIDFIeld]);
    const editAction = useCallback(() => {
        const { node } = contextMenuState;
        setEditNodeOpen({
            ...node,
            ID: node[nodeIDFIeld],
            AllowedNodeTypes: [node[nodeTypeField]],
        });
        handleClose();
    }, [handleClose, contextMenuState, nodeTypeField, nodeIDFIeld]);
    const editTranslationsAction = useCallback(() => {
        const { node } = contextMenuState;
        setTanslationOpen({ ...node, ID: node[nodeIDFIeld] });
        handleClose();
    }, [handleClose, contextMenuState, nodeIDFIeld]);

    useEffect(() => {
        if (fref) fref.current = { ...fref.current, addRootAction };
    }, [fref, addRootAction]);

    const dispatch = useDispatch();
    function addBookmark() {
        function gettingParentsLabel(object, label) {
            let arr = []
            function deepNestIteration(object, label) {
                for (var i in object) {
                    if (i === 'parentNode') {
                        if (object[i].code) {
                            arr.push(object[i].code)
                        } else {
                            arr.push(object[i].label)
                        }
                        deepNestIteration(object[i], label)
                    }
                }
            }
            deepNestIteration(object, label)
            arr.reverse()
            return arr
        }
        let parentsLabel = gettingParentsLabel(contextMenuState.node, "Value").join(" - ")
        dispatch(
            menuBookmarked({
                ID: contextMenuState.node.ID, // contextMenuState.node.ID if needed
                Label: contextMenuState.node.Value,
                Route: contextMenuState.node.route,
                DirectParentLabel: parentsLabel,
            })
        );
    }
    function removeBookmark() {
        dispatch(
            menuUnbookmarked({
                ID: contextMenuState.node.ID, // contextMenuState.node.ID if needed
                Label: contextMenuState.node.Value,
                Route: contextMenuState.node.route,
                DirectParentLabel: contextMenuState.node.parentNode.label,
            })
        );
    }
    const addedBookmarks = useSelector(state => state.userPref.Bookmarks);
    const isAlreadyBookmarked = addedBookmarks?.some(element => {
        if (element.Route === contextMenuState?.node?.route) {
            return true;
        }
        return false;
    });

    return (
        <>
            {contextMenuState?.node ? (
                <Menu
                    onContextMenu={ev => {
                        ev.stopPropagation();
                        ev.preventDefault();
                    }}
                    open={contextMenuState !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenuState !== null
                            ? {
                                top: contextMenuState.mouseY,
                                left: contextMenuState.mouseX,
                            }
                            : undefined
                    }
                >
                    {CustomActions(contextMenuState)}
                    {CustomActions(contextMenuState) ? <Divider /> : null}
                    {contextMenuState?.node?.route && (
                        <Box>
                            <ActionMenu
                                action={() =>
                                    isAlreadyBookmarked
                                        ? removeBookmark()
                                        : addBookmark()
                                }
                                Icon={
                                    isAlreadyBookmarked ? (
                                        <BookmarkRemoveOutlinedIcon />
                                    ) : (
                                        <BookmarkAddOutlinedIcon />
                                    )
                                }
                                label={
                                    isAlreadyBookmarked
                                        ? t("tree.RemoveFromBookmarks")
                                        : t("tree.AddBookmark")
                                }
                            />
                            <Divider />
                        </Box>
                    )}
                    <ActionMenu
                        action={addAction}
                        Icon={<AddOutlinedIcon />}
                        label={t("tree.Add", { value: nodeTitle })}
                        disabled={
                            contextMenuState?.node?.CanAddNode === false ||
                            isAddDisable(contextMenuState)
                        }
                    />
                    <ActionMenu
                        action={deleteAction}
                        Icon={<DeleteOutlinedIcon />}
                        label={t("tree.Delete", { value: nodeTitle })}
                        disabled={
                            contextMenuState?.node?.CanDeleteNode === false
                        }
                    />
                    <ActionMenu
                        action={editAction}
                        Icon={<EditOutlinedIcon />}
                        label={t("tree.Edit", { value: nodeTitle })}
                        disabled={contextMenuState?.node?.CanEdit === false}
                    />
                    <ActionMenu
                        action={editTranslationsAction}
                        Icon={<LanguageIcon />}
                        label={t("tree.EditTranslation", { value: nodeTitle })}
                        disabled={contextMenuState?.node?.CanEdit === false}
                    />
                </Menu>
            ) : null}
            {Boolean(editNodeOpen) ? (
                <NodeDialog
                    Form={Form}
                    label={"node"}
                    open={Boolean(editNodeOpen)}
                    rowParams={editNodeOpen}
                    setOpen={setEditNodeOpen}
                    URL={URL}
                    baseURL={baseURL}
                    BaseForm={BaseForm}
                    onClose={(node, isEditing) => onFormClose(node, isEditing)}
                />
            ) : null}
            {Boolean(deleteNodeOpen) ? (
                <DeleteDialog
                    open={Boolean(deleteNodeOpen)}
                    rowParams={deleteNodeOpen}
                    setOpen={setDeleteNodeOpen}
                    URL={URL}
                    onClose={node => onDelete(node)}
                />
            ) : null}
            {Boolean(tanslationOpen) ? (
                <TranslationDialog
                    open={Boolean(tanslationOpen)}
                    setOpen={setTanslationOpen}
                    rowParams={tanslationOpen}
                    baseURL={URL}
                />
            ) : null}
        </>
    );
};

export const NodeDialog = ({
    label,
    open,
    setOpen,
    rowParams,
    URL,
    onClose = () => {
        /* Will execute parent's given method  */
    },
    Form,
    baseURL = URL,
    BaseForm = Form,
}) => {
    const isEditing = Boolean(rowParams?.ID);
    const token = useSelector(state => state.auth.Token);

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { reset, handleSubmit, formState } = methods;

    function closeAndClearForm(rowParams) {
        setOpen(false);
        onClose(rowParams, isEditing);
        reset();
    }

    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const [sessionVerification, setSessionVerification] = useState(false)
    useCheckAndRevalidateToken(setSessionVerification, [formState.isSubmitting])
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


    const manageSubmit = async data => {
        let result;
        try {
            if (isEditing) {
                await editModel(data, data.ID, token, URL);
            } else {
                const response = await addNewModel(data, token, baseURL);
                data = {
                    ...data,
                    ...response,
                    ID: response.data.ObjectID,
                };
            }
            result = { ...rowParams, ...data };
        } catch (e) {
            console.error(e);
        }
        closeAndClearForm(result);
    };

    const isDisabled = formState.isSubmitting || !formState.isDirty;

    return (
        <Dialog open={Boolean(open)} sx={{ w: 1 }} fullWidth>
            {sessionVerification ? <SessionVerificationDialog sessionVerification={sessionVerification} /> : null}

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(manageSubmit)}>
                    <DialogTitle sx={{ typography: "body1", display: "flex" }}>
                        {isEditing
                            ? t("common.Editing")
                            : t("common.CreatingNew")}{" "}
                        <Typography
                            color="secondary"
                            sx={{ ml: 1 / 2, textTransform: "lowercase" }}
                        >
                            {label}
                        </Typography>{" "}
                        : {rowParams?.Value}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }} />
                        {isEditing ? (
                            <Form node={rowParams} />
                        ) : (
                            <BaseForm node={rowParams} />
                        )}
                    </DialogContent>
                    <DialogActionsForm>
                        <BasicButton
                            type="button"
                            style={{
                                position: "relative",
                                margin: 4,
                            }}
                            onClick={() => closeAndClearForm()}
                        >
                            {t("common.Cancel")}
                        </BasicButton>
                        <BasicButton
                            type="submit"
                            style={{ margin: 4, marginRight: 0 }}
                            disabled={isDisabled}
                        >
                            {t("common.Save")}
                        </BasicButton>
                    </DialogActionsForm>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export const DeleteDialog = ({
    open,
    setOpen,
    rowParams,
    URL,
    onClose = () => {
        /* Execute parent's given method  */
    },
}) => {
    const token = useSelector(state => state.auth.Token);
    const handleCancel = () => {
        setOpen(false);
        onClose();
    };
    const handleDelete = async () => {
        setOpen(false);
        await deleteModel(rowParams.ID, token, URL);
        onClose(rowParams);
    };
    return (
        <Dialog
            open={open}
            aria-labelledby="delete-dialog"
            aria-describedby="delete-dialog-message"
        >
            <DialogContent>
                <DialogContentText id="delete-dialog-message">
                    {t("dialog.DeleteConfirmationNode")} : {rowParams.Value}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <BasicButton onClick={handleCancel}>
                    {t("common.Cancel")}
                </BasicButton>
                <BasicButton onClick={handleDelete} color="secondary">
                    {t("common.Delete")}
                </BasicButton>
            </DialogActions>
        </Dialog>
    );
};

export const findNodeByID = (id, tree) => {
    return id && tree.length
        ? tree.find(e => e.ID === id) ??
        findNodeByID(
            id,
            tree.reduce(
                (a, e) => (e.Children ? a.concat(e.Children) : a),
                []
            )
        )
        : null;
};
