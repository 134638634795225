import React, { useCallback, useState, useEffect } from "react";
import {
    Box,
    IconButton,
    Button,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import {
    isArrayLength,
    dateTimeToString,
    historyPush,
    showPreview,
} from "../../js/utils/genericMethods";
import { useDispatch, useSelector } from "react-redux";
import { mainRed } from "../../themes/GlobalTheme";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { addNewModel, fetchData, downloadFile } from "../../js/utils/backend";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import RestoreIcon from "@mui/icons-material/Restore";
import { requiredCell } from "../../js/utils/inputValidations";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMoveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { documentDiagramRoute } from "../../routers/MenuRouter";
import * as r from "../../js/constants/routes";
import {
    useDebounceEffect,
    useEditionRightsPerEntityAccess,
    useHasRightPerEntityAccess,
} from "../../js/utils/customHooks";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { TranslationDialog, ValidationChip } from "../DataGrid/cst_DataGrid";
import * as documentDiagram from "../../diagrams/documentDiagram.json";
import SharedSettings from "../../common/sharedSettings.json";
import DiagramIcon from "@mui/icons-material/TransformOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import { BasicTooltip } from "../Widgets/StyledComponents";
import MediaChunksUploaderDialog from "./DocumentView/MediaChunksUploaderDialog";
import MoveDialog from "./DocumentView/MoveDialog";
import EditMediaDialog from "./DocumentView/EditMediaDialog";
import BatchMetadataDialog from "./DocumentView/BatchMetadataDialog";
import { KebabItem, MenuKebab } from "../Widgets/MenuKebab";
import { DocumentUploadInstructions, mimeTypeCategories } from "./DocumentView/cst_DocumentsView";

export const isPhotoVideo = "PhotoVideo";
export const isDocuments = "Documents";

/** First utility of documents view is to display a Document grid along a dropzone feature to drop and upload files for the node that we're viewing (nodeID). Second purpose is to do the same with a station : add documents to a station (stationID). */
const DocumentsView = ({
    label,
    baseURL = "Document",
    isHistoryViewer = false,
}) => {
    const {
        historyItem,
        fileType,
        nodeID,
        hasDocuments,
        otEntityID,
        element,
        elementID,
        otEntityTreeID,
    } = useParams();

    const apiRef = useGridApiRef();
    const cellFocusField = "Code";
    const isEddFile = Boolean(element) && element === "EDDFile";
    const [forceGridRefresh, setForceGridRefresh] = useState(false);
    const [treeNode, setTreeNode] = useState();
    const [parentField, setParentField] = useState();
    const notDraft = parentField?.State !== "Draft";
    const entityID = treeNode?.OTEntityID ?? otEntityID;
    const basicEditionRights = useEditionRightsPerEntityAccess(entityID, [
        "EntityManager",
        "Contributor",
    ]);
    const isReaderOfEntity = useHasRightPerEntityAccess(entityID, ["Reader"]);
    const isExternalOfEntity = useHasRightPerEntityAccess(entityID, [
        "External",
    ]);
    const readingRightsOnly = (isEddFile && notDraft) || isReaderOfEntity;
    const hasExternalEditionRights =
        isEddFile && isExternalOfEntity && !readingRightsOnly;
    const editingRights = hasExternalEditionRights || basicEditionRights;

    const [isLoading, setIsLoading] = useState(element || nodeID);
    const token = useSelector(state => state.auth.Token);
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const diagramStates = documentDiagram.default.States;
    const hasTransitionRight = useEditionRightsPerEntityAccess(entityID, [
        "EntityManager",
    ]);
    const validateTransition = documentDiagram.default.Transitions.find(
        e => e.Name === "ForValidation"
    );
    const diagramTransitions =
        hasTransitionRight && !isHistoryViewer
            ? documentDiagram.default.Transitions
            : [];
    const history = useHistory();

    /** The locale is used to refresh the title of the document grid, by fetching and refreshing again the 'treeNode' */
    const locale = useSelector(state => state.i18n.locale);
    const userID = useSelector(state => state.auth.UserID);

    let gridSpecificColumns = [
        {
            field: "CreatedOn",
            headerName: t("field.CreatedOn"),
            width: 150,
            editable: false,
            valueFormatter: params =>
                dateTimeToString(params.value, "DateTime"),
        },
        {
            field: "Value",
            headerName: t("field.Name"),
            width: 150,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Value", "Value");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "State",
            headerName: t("field.State"),
            renderCell: params => {
                const state = diagramStates.find(e => e.Name === params.value);
                let availableTransitions = diagramTransitions.filter(
                    e => e.From === state?.Name
                );
                if (
                    params.value === "Draft" &&
                    params.row.UploadedBy === userID &&
                    !isReaderOfEntity
                ) {
                    availableTransitions = [validateTransition];
                }
                return (
                    <ValidationChip
                        hasRights={editingRights}
                        state={state}
                        availableTransitions={availableTransitions}
                        rowData={params.row}
                        baseURL={baseURL}
                        afterTransition={() => {
                            setForceGridRefresh(fgr => !fgr);
                        }}
                    />
                );
            },
            minWidth: 165,
            editable: false,
        },
        {
            field: "IsPrimaryEDDFile",
            headerName: t("field.IsPrimaryEDDFile"),
            width: 120,
            editable: false,
            type: "boolean",
        },
        {
            field: "Filename",
            headerName: t("field.Filename"),
            flex: 1,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "FileName",
                    "FileName"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Author",
            headerName: t("field.Author"),
            width: 200,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Author",
                    "Author"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "DocumentCategory",
            headerName: t("field.DocumentCategory"),
            width: 150,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "DocumentCategory",
                    "DocumentCategory"
                );
                return { ...params.props, error: hasError };
            },
            valueFormatter: params => t(`field.${params.value}`),
        },
    ];

    if (isHistoryViewer) {
        gridSpecificColumns = [
            {
                headerName: t("field.UpdatedByUser"),
                field: "UpdatedByUser",
                width: 150,
                valueFormatter: params => {
                    return `${params?.value?.Name} ${
                        params?.value?.IGG ? `| ${params?.value?.IGG}` : ""
                    } ${params?.value?.External ? "| External" : ""}`;
                },
            },
            {
                headerName: t("field.LastUpdate"),
                field: "LastUpdate",
                width: 150,
                valueFormatter: params =>
                    dateTimeToString(params.value, "DateTime"),
            },
            {
                headerName: t("field.CreatedByUser"),
                field: "CreatedByUser",
                width: 150,
                valueFormatter: params => {
                    return `${params?.value?.Name} ${
                        params?.value?.IGG ? `| ${params?.value?.IGG}` : ""
                    } ${params?.value?.External ? "| External" : ""}`;
                },
            },
            {
                headerName: t("common.Location"),
                field: "OTEntityTree",
                width: 150,
            },

            ...gridSpecificColumns,
        ];
    }

    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            <MenuKebab disabled={editing || isLocalLoading}>
                {/* REMPLACE THIS BY A DOWNLOAD FILE FUNCTION (e.g. downloadFile), exactly as downloading a file but in a tab (showing it) */}
                {showPreview(params.row.Filename) && (
                    <Button
                        size="small"
                        sx={{
                            textTransform: "capitalize",
                            width: "100%",
                            display: "block",
                        }}
                        disabled={isLocalLoading}
                    >
                        <KebabItem
                            disabled={isLocalLoading}
                            label={t("common.PreviewFile")}
                            icon={
                                <BasicTooltip
                                    title={`${t("common.PreviewFile")}`}
                                >
                                    <PreviewIcon />
                                </BasicTooltip>
                            }
                            onClick={() => {
                                downloadFile(
                                    token,
                                    "preview",
                                    `/${params.row.MediaID}/${params.row.OTEntitySelector.ObjectID}`
                                );
                            }}
                        />
                    </Button>
                )}
                <Button
                    size="small"
                    sx={{
                        textTransform: "capitalize",
                        width: "100%",
                        display: "block",
                    }}
                    disabled={isLocalLoading}
                >
                    <KebabItem
                        disabled={isLocalLoading}
                        label="Download file"
                        icon={
                            <BasicTooltip title={`${t("common.Download")}`}>
                                <DownloadOutlinedIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            downloadFile(
                                token,
                                "download",
                                `/${params.row.MediaID}/${params.row.OTEntitySelector.ObjectID}`
                            );
                        }}
                    />
                </Button>
            </MenuKebab>

            {!editingRights || readingRightsOnly ? null : (
                <>
                    <GridActionsCellItem
                        label={t("common.Translate")}
                        icon={
                            <BasicTooltip title={t("common.Translate")}>
                                <LanguageIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            setOpenTranslateDialog(true);
                            setRowParams(params.row);
                        }}
                        disabled={editing || isLocalLoading}
                    />
                    <GridActionsCellItem
                        label="History"
                        icon={
                            <BasicTooltip title={t("common.ChangesHistory")}>
                                <RestoreIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            setCurrentDocument(params.id);
                            return historyPush(
                                history,
                                `/history/${params.id}${history.location.pathname}`
                            );
                        }}
                        disabled={editing || isLocalLoading}
                    />
                    <GridActionsCellItem
                        label="Edit file"
                        icon={
                            <BasicTooltip title={`${t("common.Edit")}`}>
                                <EditOutlinedIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            editMedia(params.row.ID);
                        }}
                        disabled={editing || isLocalLoading}
                    />
                </>
            )}
        </>,
    ];

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openMoveDialog, setOpenMoveDialog] = useState(false);
    const [openTranslateDialog, setOpenTranslateDialog] = useState(false);
    const [rowParams, setRowParams] = useState();
    const [openBatchMetadataDialog, setOpenBatchMetadataDialog] = useState();
    const [media, setMedia] = useState([]);
    const [currentDocument, setCurrentDocument] = useState(null);

    const [mediaMetadata, setMediaMetadata] = useState(null); // important, will trigger process of upload

    const [triggerEditDialog, setTriggerEditDialog] = useState(false); // this is used only for single file upload, to trigger the edit popup programmatically
    /** For single filed upload only. Will open EditMediaDialog if we are on a EDDFile document folder. So the user can immediately edit (barely forced, but he can cancel) metadata of recently uploaded document  */
    useEffect(() => {
        if (currentDocument && triggerEditDialog) {
            // (isEddFile && !openDialog && !isArrayLength(media)
            editMedia(currentDocument);
            setTriggerEditDialog(false);
        }
        // eslint-disable-next-line
    }, [currentDocument]); // mediaMetadata, openDialog, isEddFile, media

    const [uploadedFile, setUploadedFile] = useState();

    const mediaIsUploaded = uploadedFile?.MediaID && !uploadedFile?.Replacing;

    const [skipDocument, setSkipDocument] = useState(null);
    const filterIsReader = readingRightsOnly ? `&DocumentCategory=Public` : "";
    const entityToFieldWanted = {
        sampling: {
            parentField: "SamplingID",
            valueField: "SubSamplingID",
            url: `filter[SamplingID]=${elementID}&filter[IsPhotoVideo]=true${filterIsReader}`,
        },

        eddfile: {
            parentField: "EDDFileID",
            valueField: "Value",
            url: `filter[EDDFileID]=${elementID}${filterIsReader}`,
        },
        station: {
            parentField: "StationID",
            valueField: "Name",
            url: `filter[StationID]=${elementID}&filter[IsPhotoVideo]=${
                fileType === isPhotoVideo
            }${filterIsReader}`,
        },
    };
    const getElementInfos = fieldWanted => {
        for (const key in entityToFieldWanted) {
            if (Boolean(element) && element.toLowerCase().includes(key))
                return entityToFieldWanted[key][fieldWanted];
        }
    };

    const [triggerBatchMetadataDialog, setTriggerBatchMetadataDialog] =
        useState();
    const [batchMetadaObject, setBatchMetadataObject] = useState(null);

    // Create a document with default metadata (POST), but should skip if the file is overwriting an existing media in document.
    useEffect(() => {
        if (uploadedFile && !skipDocument && !batchMetadaObject) {
            // single file upload then metadata to encode for PUT
            const data = {
                MediaID: uploadedFile.MediaID,
                Created: true,
                Hidden: false,
                Value: "",
                FileName: media?.name,
                Author: "",
                DocumentCategory: "Public",
                OTEntityTreeID: treeNode?.ID ?? otEntityTreeID,
                OTEntityID: treeNode?.OTEntityID ?? otEntityID, //*
                [getElementInfos("parentField")]: elementID, //*
                StudyID: treeNode?.StudyID,
            };
            addNewModel(data, token, r.document)
                .then(response => {
                    setCurrentDocument(response.data.ObjectID);
                })
                .then(() => {
                    setSkipDocument(null);
                });
        }
        // eslint-disable-next-line
    }, [uploadedFile, skipDocument]);

    // Before creating a document, the user has registered some metadata, that will be applied to the document to create :
    useEffect(() => {
        if (uploadedFile && !skipDocument && batchMetadaObject) {
            const dataFromBatchMetadata = {
                MediaID: uploadedFile.MediaID,
                Created: true,
                Hidden: false,
                OTEntityTreeID: treeNode?.ID ?? otEntityTreeID,
                OTEntityID: treeNode?.OTEntityID ?? otEntityID, //*
                [getElementInfos("parentField")]: elementID, //*
                StudyID: treeNode?.StudyID,
                // From batch metadata form :
                Value: uploadedFile.Filename.replace(/\.[^/.]+$/, ""), // .extension removed to have a clean name
                FileName: uploadedFile.Filename,
                Author: batchMetadaObject.Author ?? "",
                DocumentCategory:
                    batchMetadaObject.DocumentCategory ?? "Public",
                PublicationDate: batchMetadaObject.PublicationDate ?? undefined,
                RelatedZoneIDs: batchMetadaObject.RelatedZoneIDs,
                RelatedStudyIDs: batchMetadaObject.RelatedStudyIDs,
                RelatedStationIDs: batchMetadaObject.RelatedStationIDs,
                RelatedStationGroupIDs:
                    batchMetadaObject.RelatedStationGroupIDs,
                Ref_PhotoContextID: batchMetadaObject.Ref_PhotoContextID,
            };
            addNewModel(dataFromBatchMetadata, token, r.document)
                .then(response => {
                    setCurrentDocument(response.data.ObjectID);
                })
                .then(() => {
                    setSkipDocument(null);
                });
        }
        // MEMO WARNIGN CORN
        // eslint-disable-next-line
    }, [uploadedFile, skipDocument, batchMetadaObject]);

    /** We will fetch the tree node, which is necessary further to POST / PUT a media file  */
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            let route = "/OTEntityTree";
            const nodeFetched = await fetchData(route, token, "ID", nodeID);
            if (isSubscribed) {
                setTreeNode(nodeFetched.data);
                setIsLoading(false);
            }
        };
        if (nodeID) {
            fetching();
        }
        return () => (isSubscribed = false);
    }, [nodeID, token, setTreeNode, locale]);
    /*  In case we're in the documents/photo videos/eddfiles of a parent entity (e.g. stationAir), we fetch the infos of the parent to display its name on screen */
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const parentFieldFetched = await fetchData(
                element,
                token,
                "ID",
                elementID
            );
            if (isSubscribed) {
                setParentField(parentFieldFetched.data);
                setIsLoading(false);
            }
        };
        if (element && elementID) {
            fetching();
        }
        return () => (isSubscribed = false);
    }, [element, token, elementID]);

    /** When user want to edit metadatas of a document, we'll fetch it and pass data to Dialog */
    function editMedia(id) {
        const fetching = () => {
            fetchData("Document/getDocument", token, "ID", id).then(
                response => {
                    setMediaMetadata(response.data);
                    setOpenEditDialog(true);
                }
            );
        };
        fetching();
    }

    /**  First step where user interacts with dropzone or add a document; onDrop will get the list of files and sort them, for further use */
    const onDrop = useCallback(async acceptedFiles => {
        let sortedArray = null;
        sortedArray = acceptedFiles?.sort((a, b) =>
            a.name.toString().localeCompare(b.name)
        );
        if (sortedArray.length > 1) {
            // We'll upload several files, but before we'll add batch metadata (BatchMetadataDialog)
            setTriggerBatchMetadataDialog(sortedArray);
            setOpenBatchMetadataDialog(true);
        } else {
            // We'll upload only one file
            setTriggerEditDialog(true);
            setMedia(sortedArray);
        }
    }, []);

    /** We have to disable dropzone while hovering on a Datagrid toolbar button (= Move), for some reason, dropzone don't allow click there... see more : https://github.com/react-dropzone/react-dropzone/issues/1127 */
    const [onHoverDisableDropzone, setOnHoverDisableDropzone] = useState(false);
    function mouseEntering() {
        setOnHoverDisableDropzone(true);
    }
    function mouseLeaving() {
        setOnHoverDisableDropzone(false);
    }
    const deferEnter = useDebounceEffect(mouseEntering, 100);
    const deferLeave = useDebounceEffect(mouseLeaving, 100);



    const documentNameToLabel = type => {
        const typeToLabel = {
            Documents: t("grid.title.Documents"),
            PhotoVideo: t("grid.title.PhotoVideo"),
            Files: t("grid.title.Files"),
        };

        return typeToLabel[type] ? typeToLabel[type] + " : " : "";
    };

    /** Check file type then document view type, to allow or not the upload. */
    function fileTypeValidator(file) {
        if (fileType === isDocuments) {
            if (file.type.includes("image") || file.type.includes("video")) {
                const allowedDocuments = Object.values(mimeTypeCategories.Documents)
                console.log("")
                return {
                    code: "wrong-document-file",
                    message: t("input.validation.WrongDocumentExtension", {
                        value: allowedDocuments
                    }),
                };
            }
        } else if (fileType === isPhotoVideo) {
            if (!file.type.includes("image") && !file.type.includes("video")) {
                const allowedPhotoVideo = Object.values(mimeTypeCategories.PhotoVideo)
                return {
                    code: "wrong-photovideo-file",
                    message: t("input.validation.WrongPhotoVideoExtension", {
                        value: allowedPhotoVideo
                    }),
                };
            }
        }
    }

    /** Destructuring needed parameters for react-dropzone to work. And setting wished specifications. */
    const isUploadDisabled = !editingRights || readingRightsOnly;
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        fileRejections,
        isDragReject,
        open,
    } = useDropzone({
        maxFiles: SharedSettings.MaxFilesToUpload,
        maxSize: 2147483648, // 2GB
        multiple: true,
        noClick: true,
        noKeyboard: true,
        onDrop,
        label: label,
        validator: fileTypeValidator, 
        disabled: isUploadDisabled || onHoverDisableDropzone,
    });
    const [inSelection, setInSelection] = useState(false);
    const [selection, setSelection] = useState([]);
    /** When a document is moved to another node, we'll set again the inSelection to false (so only 'Move' button is displayed) */
    useEffect(() => {
        if (nodeID) {
            handleCancelMoveClick();
        }
    }, [nodeID, setInSelection]);

    const handleMoveClick = () => {
        setInSelection(true);
    };
    const handleDestinationClick = () => {
        const IDs = [...apiRef.current.getSelectedRows().keys()];
        setOpenMoveDialog(IDs);
    };
    const handleCancelMoveClick = () => {
        setInSelection(false);
        setSelection([]);
    };

    const nodeDocumentParamURL =
        nodeID && `OTEntityTreeID=${nodeID}${filterIsReader}`;

    const [toggleInfo, setToggleInfo] = useState(false);
    useEffect(() => {
        if (!toggleInfo && fileRejections[0]?.errors?.[0]?.message) {
            setToggleInfo(true);
        }
    }, [fileRejections, toggleInfo]);

    if ((element && !parentField) || isLoading) {
        return <ScreenLoading />;
    }
    return (
        <Box
            {...getRootProps({
            })}
            sx={{
                border: isDragActive
                    ? `2px dashed ${mainRed}`
                    : `2px solid transparent`,
                backgroundColor: isDragActive ? "#eee" : null,
                filter: isDragActive && "brightness(0.85)",
            }}
        >
            <Box
                sx={{
                    mx: 5,
                }}
            >
                <Box
                    sx={{
                        typography: "h3",
                        pt: 6,
                        pb: 2,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {isHistoryViewer && `${t("common.ChangesHistory")}: `}
                    {documentNameToLabel(fileType)}
                    {treeNode?.Value ??
                        parentField?.[getElementInfos("valueField")]}
                    {!toggleInfo && editingRights && !isHistoryViewer && (
                        <IconButton
                            sx={{ ml: 1 }}
                            onClick={_e => setToggleInfo(!toggleInfo)}
                        >
                            <BasicTooltip
                                title={`${t("view.document.HelpFileUpload")}`}
                            >
                                <HelpIcon color="primary" />
                            </BasicTooltip>
                        </IconButton>
                    )}
                </Box>
                <DocumentUploadInstructions
                    editingRights={editingRights}
                    toggleInfo={toggleInfo}
                    setToggleInfo={(e) => setToggleInfo(e)}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    fileType={fileType}
                    fileRejections={fileRejections}
                />
            </Box>
            {isArrayLength(media) && (
                <MediaChunksUploaderDialog
                    media={media}
                    setMedia={setMedia}
                    token={token}
                    setUploadedFile={e => setUploadedFile(e)}
                    otEntityId={treeNode?.OTEntityID ?? otEntityID}
                    forceGridRefresh={forceGridRefresh}
                    setForceGridRefresh={setForceGridRefresh}
                    OTEntityTreeID={treeNode?.ID ?? otEntityTreeID}
                    currentDocument={currentDocument}
                    mediaIsUploaded={mediaIsUploaded}
                    setSkipDocument={e => setSkipDocument(e)}
                    fileType={fileType}
                />
            )}
            <Box
                sx={{
                    typography: "h2",
                    display: "flex",
                    justifyContent: "center",
                    width: "auto",
                }}
            >
                <input {...getInputProps()} />
            </Box>
            {/* Will open only when several files are uploaded (>1) */}
            {openBatchMetadataDialog && (
                <BatchMetadataDialog
                    openBatchMetadataDialog={openBatchMetadataDialog}
                    setOpenBatchMetadataDialog={e =>
                        setOpenBatchMetadataDialog(e)
                    }
                    otEntityId={treeNode?.OTEntityID ?? otEntityID}
                    isEddFile={isEddFile}
                    triggerBatchMetadataDialog={triggerBatchMetadataDialog}
                    batchMetadaObject={batchMetadaObject}
                    setBatchMetadataObject={e => setBatchMetadataObject(e)}
                    setMedia={e => setMedia(e)}
                />
            )}
            <EditMediaDialog
                openEditDialog={openEditDialog}
                setOpenEditDialog={setOpenEditDialog}
                treeNode={treeNode}
                media={media}
                setMedia={e => setMedia(e)}
                token={token}
                element={element}
                setForceGridRefresh={setForceGridRefresh}
                mediaMetadata={mediaMetadata}
                otEntityId={treeNode?.OTEntityID ?? otEntityID}
                setMediaMetadata={e => {
                    setMediaMetadata(e);
                }}
                setCurrentDocument={e => setCurrentDocument(e)}
                isEddFile={isEddFile}
                triggerBatchMetadataDialog={triggerBatchMetadataDialog}
            />
            <TranslationDialog
                label={label}
                open={openTranslateDialog}
                setOpen={setOpenTranslateDialog}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <MoveDialog
                openMoveDialog={openMoveDialog}
                setOpenMoveDialog={setOpenMoveDialog}
                OTEntityID={treeNode?.OTEntityID}
                onDialogClose={handleCancelMoveClick}
            />
            {hasDocuments || (element && parentField?.State) ? (
                <DataGridComponent
                    apiRef={apiRef}
                    baseURL={
                        isHistoryViewer
                            ? `${baseURL}/history/${historyItem}`
                            : baseURL
                    }
                    cellFocusField={cellFocusField}
                    paramURL={
                        isHistoryViewer
                            ? ""
                            : nodeDocumentParamURL ?? getElementInfos("url")
                    }
                    label={label}
                    gridSpecificColumns={gridSpecificColumns}
                    specificActionsColumns={specificActionsColumns}
                    isDeleteDisabled={
                        !editingRights ||
                        readingRightsOnly ||
                        inSelection ||
                        isHistoryViewer
                    }
                    isEditDisabled={true}
                    initialState={{
                        sorting: {
                            sortModel: [
                                isHistoryViewer
                                    ? { field: "LastUpdate", sort: "desc" }
                                    : { field: "CreatedOn", sort: "desc" },
                            ],
                        },
                    }}
                    editingRights={editingRights}
                    toolbar={
                        isHistoryViewer
                            ? { add: false }
                            : {
                                  newAction: editingRights && (
                                      <>
                                          {isUploadDisabled ? null : (
                                              <ButtonToolbar
                                                  type="button"
                                                  titleTooltip={t(
                                                      "view.document.AddDocument"
                                                  )}
                                                  disabled={inSelection}
                                                  title={t("common.Add")}
                                                  startIcon={
                                                      <UploadFileOutlinedIcon />
                                                  }
                                                  specialaction={{
                                                      ...getRootProps({}),
                                                  }}
                                                  onClick={open}
                                              />
                                          )}
                                          <ButtonToolbar
                                              titleTooltip={t(
                                                  "common.AccessWorkflow"
                                              )}
                                              title={t("common.Workflow")}
                                              startIcon={<DiagramIcon />}
                                              onClick={() =>
                                                  historyPush(
                                                      history,
                                                      documentDiagramRoute
                                                  )
                                              }
                                          />
                                          {/* If we are in the case where document folder is a document node on the tree, we can move elsewhere (node)  */}
                                          {Boolean(
                                              element
                                          ) ? null : !inSelection ? (
                                              <ButtonToolbar
                                                  type="button"
                                                  onMouseEnter={deferEnter}
                                                  onMouseOut={deferLeave}
                                                  titleTooltip={t(
                                                      "view.document.MoveDocument"
                                                  )}
                                                  title={t("common.Move")}
                                                  startIcon={
                                                      <DriveFileMoveIcon />
                                                  }
                                                  onClick={handleMoveClick}
                                              />
                                          ) : (
                                              <>
                                                  <ButtonToolbar
                                                      titleTooltip={t(
                                                          "view.document.CancelMove"
                                                      )}
                                                      title={t("common.Cancel")}
                                                      startIcon={
                                                          <CancelOutlinedIcon />
                                                      }
                                                      onClick={
                                                          handleCancelMoveClick
                                                      }
                                                  />
                                                  <ButtonToolbar
                                                      titleTooltip={t(
                                                          "view.document.SelectDestination"
                                                      )}
                                                      title={t(
                                                          "view.document.Destination"
                                                      )}
                                                      startIcon={
                                                          <DriveFileMoveIcon />
                                                      }
                                                      onClick={
                                                          handleDestinationClick
                                                      }
                                                      disabled={
                                                          !isArrayLength(
                                                              selection
                                                          )
                                                      }
                                                  />
                                              </>
                                          )}
                                      </>
                                  ),
                                  add: false,
                              }
                    }
                    visitedRow={currentDocument}
                    forceGridRefresh={forceGridRefresh}
                    hideActionColumn={
                        (isEddFile && isReaderOfEntity) || isHistoryViewer
                    }
                    checkboxSelection={inSelection}
                    columnVisibilityModel={{
                        IsPrimaryEDDFile: isEddFile,
                        actions: !inSelection,
                    }}
                    selectionModel={selection}
                    onSelectionModelChange={newSelectionModel => {
                        setSelection(newSelectionModel);
                    }}
                    withBackButton={isHistoryViewer}
                />
            ) : null}
        </Box>
    );
};
export default DocumentsView;
